body {
  font-size: 16px;
  position: relative;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  * { box-sizing: border-box; }
}

@import "variables";
@import "icomoon";
@import "home";
@import "listings";

.footer {
  margin-top: 1.5rem;
  padding: .5rem 0;
  color: #808080;
  font-family: $proxima;
  text-align: center;
  font-size: .9rem;
}
