.listings {
  padding: 1rem;
  font-family: $proxima;
  color: #505050;

  h2,h3,h4,h5,h6 {font-family: $didot}

  h2 {
    color: #6c4692;
    margin: 2rem 0;
    text-transform: uppercase;
    font-style: italic;
    letter-spacing: .06rem;
    text-align: center;
  }

  .no-vacancies{
    font-style: italic;
    font-family: $didot;
    font-size: 1.3rem;
    font-weight: bold;
    text-align: center;
    color: #8a8a8a;
  }

}

.listings-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.listing {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 300px;
  margin: .5rem;
  padding: .65rem;
  box-shadow: 1px 1px 6px #ccc;
  border-radius: 6px;

  img { max-width: 100%; }

  .details {
    padding: 0 .45rem;
  }

  h4 {
    font-size: 1.3rem;
    margin: .45rem 0 .25rem;
  }

  .city{
    color: #937eb3;
    margin: 0;
    font-size: .9rem;
    font-weight: 600;
    font-style: italic;
  }

  ul.pricing {
    padding: 0;
    list-style: none;

    li {
      line-height: 1.5rem;
    }
    span {
      font-weight: bold;
      font-size: 1.1rem;
    }
    em {
      font-size: .9rem;
    }
  }

  ul.restrictions {
    padding: 0;
    list-style: none;
    font-weight: bold;
    text-transform: uppercase;
    font-size: .8rem;
    letter-spacing: .06rem;
    line-height: 1.3rem;
  }

  .btn {
    display: block;
    margin: 1rem 0 0;
    padding: .75rem 1.75rem;
    font-family: $didot;
    font-weight: bold;
    font-style: italic;
    text-align: center;
    color: #fff;
    background: #13b1b1;
    font-size: 20px;
    text-decoration: none;
    border-radius: 3px;
    transition: all .2s;

    &:hover {
      background: #0a91a7;
    }
  }
}

@media only screen and (max-width: 500px) {
  .listing {
    max-width: 450px;
  }
}
