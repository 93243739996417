#hero {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background-image: url("img/home-purple-bkg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 4rem 2rem;
  overflow: scroll;

  &.scroll {
    min-height: 96vh;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      font-family: $proxima;
      font-weight: bold;
      display: block;
      color: #fff;
      border-style: solid;
    	border-width: 0.2rem 0.2rem 0 0;
    	height: 0.95rem;
    	bottom: 1.5rem;
    	vertical-align: top;
    	width: 0.95rem;
      opacity: .65;
      animation-name: bounce;
      animation-timing-function: ease-in;
      animation-duration: 1.25s;
      animation-iteration-count: infinite;
    }
  }

  @keyframes bounce {
    0%   { transform: translateY(0) rotate(135deg); }
    50%  { transform: translateY(-.5rem) rotate(135deg); }
    100% { transform: translateY(0) rotate(135deg); }
  }
}

.content {
  position: relative;
  text-align: center;
  color: #fff;
  max-width: 600px;
  .company-desc {
    font-family: $proxima;
    font-size: 20px;

    &:before {
      content: "";
      display: block;
      width: 40%;
      height: 1px;
      margin: 2rem auto 2rem;
      background-color: #fff;
    }
  }

  a.contact {
    display: inline-block;
    margin: 1rem 0 2rem;
    padding: .75rem 1.75rem;
    font-family: $didot;
    font-weight: bold;
    font-style: italic;
    color: #fff;
    background: #13b1b1;
    font-size: 20px;
    text-decoration: none;
    border-radius: 3px;
    transition: all .2s;

    &:hover {
      background: #0fd2d2;
    }
  }
}

.logo {
  width: 19%;
  max-width: 6em;
  position: relative;
  margin: 0 auto 2em;
  display: block;
}

.logotype {
  width: 70%;
  max-width: 22em;
  position: relative;
  margin: 0 auto;
  display: block;
}
